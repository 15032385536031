import CabSpinner from "@CabComponents/CabSpinner";
import { Box, Stack } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

interface Props {
  emptyMessage: string;
  msTimeout: number;
}


const NoRowOverlay = ({ emptyMessage, msTimeout }:Props): ReactElement => {
  const [showLoading, setShowLoading] = useState(true);

  //Timeout to show no rows, only shows if still no data after timeout
  //DataGride issue: https://github.com/mui/mui-x/issues/6157
  useEffect(() => {
    const timeout = setTimeout(() => setShowLoading(false), msTimeout);
    return () => clearTimeout(timeout);
  }, [msTimeout]);

  return (
    showLoading ? (
      <Box display='flex' height="100%" alignItems="center" justifyContent="center">
        <CabSpinner scale={2}/>
      </Box>
    )
      : ( 
        <Stack height="100%" alignItems="center" justifyContent="center">
          {emptyMessage}
        </Stack>
      )
  );
};

export default NoRowOverlay;